import { Vector3d } from "./Vector3d.js";
const origin = {
    x: 0,
    y: 0,
    z: 0,
};
export class Vector extends Vector3d {
    constructor(xOrCoords, y) {
        super(xOrCoords, y, origin.z);
    }
    static get origin() {
        return Vector.create(origin.x, origin.y);
    }
    static clone(source) {
        return Vector.create(source.x, source.y);
    }
    static create(x, y) {
        return new Vector(x, y);
    }
}
